<template>
  <div class="container">
    <div
      class="positon-relative"
      v-scroll-reveal="{ delay: 50, distance: '20px' }"
    >
      <div class="shane_tm_title">
        <div class="title_flex">
          <div class="left">
            <span>Portfolio</span>
            <h3>Videos, Photos, & Media</h3>
          </div>
        </div>
      </div>
      <!-- End shane_tm_title -->
      <div class="portfolio_filter">
        <ul>
          <li>
            <a
              v-on:click="activetab = 1"
              :class="[activetab === 1 ? 'active' : '']"
              >All</a
            >
          </li>
          <!-- End All for tabcontent 1 -->
          <li>
            <a
              v-on:click="activetab = 2"
              :class="[activetab === 2 ? 'active' : '']"
              >Audio</a
            >
          </li>
          <li>
            <a
              v-on:click="activetab = 3"
              :class="[activetab === 3 ? 'active' : '']"
              >Youtube</a
            >
          </li>
          <!-- End .Youtube tabcontent 3 -->
          <li>
            <a
              v-on:click="activetab = 4"
              :class="[activetab === 4 ? 'active' : '']"
              >Gallery</a
            >
          </li>
          <!-- End Mix tabcontent 4 -->
        </ul>
      </div>
      <!-- End .portfolio-filter -->
    </div>

    <div v-if="activetab === 1" class="tabcontent">
      <div class="portfolio_list">
        <ul class="gallery_zoom">
          <CoolLightBox :items="allItems" :index="index" @close="index = null">
          </CoolLightBox>
          <li v-for="(image, imageIndex) in allItems" :key="imageIndex">
            <div class="inner" @click="index = imageIndex">
              <div v-if="image.portfolioType === 'YouTube'" style="position: absolute; z-index: 2; color: white; margin: 10px;">
                <svg style="width: 30px; height: 30px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M336.2 64H47.8C21.4 64 0 85.4 0 111.8v288.4C0 426.6 21.4 448 47.8 448h288.4c26.4 0 47.8-21.4 47.8-47.8V111.8c0-26.4-21.4-47.8-47.8-47.8zm189.4 37.7L416 177.3v157.4l109.6 75.5c21.2 14.6 50.4-.3 50.4-25.8V127.5c0-25.4-29.1-40.4-50.4-25.8z"/></svg>
              </div>
              <div class="entry shane_tm_portfolio_animation_wrap">
                <img class="image" :src="image.thumb" alt="Portfolio" />
              </div>
              <!-- End .entry -->
              <div class="mobile_title">
                <h3>{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span>
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
      </div>

      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 1 -->


    <div v-if="activetab === 2" class="tabcontent">
      <div class="portfolio_list">
        <ul>
          <CoolLightBox
            :items="audioClips"
            :index="index"
            @close="index = null"
          >
          </CoolLightBox>
          <li v-for="(image, imageIndex) in audioClips" :key="imageIndex">
            <div class="inner" @click="index = imageIndex">
              <div class="entry tokyo_tm_portfolio_animation_wrap">
                <img class="image" :src="image.thumb" alt="Portfolio" />
              </div>
              <!-- End .entry -->
              <div class="mobile_title">
                <h3>{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span>
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
        <!-- End .portfolio_list -->
      </div>
    </div>
    <!-- End .tabcontent 3 -->

    <div v-if="activetab === 3" class="tabcontent">
      <div class="portfolio_list">
        <ul>
          <CoolLightBox
            :items="youtubeItems"
            :index="index"
            @close="index = null"
          >
          </CoolLightBox>
          <li v-for="(image, imageIndex) in youtubeItems" :key="imageIndex">
            <div class="inner" @click="index = imageIndex">
              <div class="entry tokyo_tm_portfolio_animation_wrap">
                <img class="image" :src="image.thumb" alt="Portfolio" />
              </div>
              <!-- End .entry -->
              <div class="mobile_title">
                <h3>{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span>
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
        <!-- End .portfolio_list -->
      </div>
    </div>
    <!-- End .tabcontent 3 -->

    <div v-if="activetab === 4" class="tabcontent">
      <div class="portfolio_list">
        <ul>
          <CoolLightBox
            :items="galleryItems"
            :index="index"
            @close="index = null"
          >
          </CoolLightBox>

          <li v-for="(image, imageIndex) in galleryItems" :key="imageIndex">
            <div class="inner" @click="index = imageIndex">
              <div class="entry tokyo_tm_portfolio_animation_wrap">
                <img class="image" :src="image.thumb" alt="Portfolio" />
              </div>
              <!-- End .entry -->
              <div class="mobile_title">
                <h3>{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span>
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
        <!-- End .portfolio_list -->
      </div>
    </div>
    <!-- End .tabcontent 4 -->
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      activetab: 1,
      youtubeItems: [
        {
          src: "https://www.youtube.com/watch?v=yvhKvS9a7z",
          thumb: require("../../assets/img/portfolio/bb1.png"),
          portfolioName: "Och, jaký žal... Ten lásky sen jak krásný byl",
          portfolioType: "YouTube",
        },
        {
          src: "https://www.youtube.com/watch?v=uRhOCY7XNL8",
          thumb: require("../../assets/img/portfolio/bb1.png"),
          portfolioName: "Kdybych se co takoveho",
          portfolioType: "YouTube",
        },
        {
          src: "https://www.youtube.com/watch?v=HUMxy4Q5P-E",
          thumb: require("../../assets/img/portfolio/bb2.png"),
          portfolioName: "Bartered Bride Opening Scene",
          portfolioType: "YouTube",
        },
        {
          src: "https://youtu.be/5S28TJ8vb1g",
          thumb: require("../../assets/img/portfolio/dehvieni1.jpg"),
          portfolioName: "Deh vieni, non tardar",
          portfolioType: "YouTube",
        },
        {
          src: "https://youtu.be/RyevUPFzFvc",
          thumb: require("../../assets/img/portfolio/figaro2.jpg"),
          portfolioName: "Venite, inginocchiatevi",
          portfolioType: "YouTube",
        },
        {
          src: "https://youtu.be/-_cgwwcYzic",
          thumb: require("../../assets/img/portfolio/come.png"),
          portfolioName: "Come scoglio",
          portfolioType: "YouTube",
        },
        {
          src: "https://youtu.be/l6g1xGxfSRA",
          thumb: require("../../assets/img/portfolio/lascia.png"),
          portfolioName: "Lascia ch'io pianga",
          portfolioType: "YouTube",
        },
        {
          src: "https://youtu.be/TPTFL5ohZwk",
          thumb: require("../../assets/img/portfolio/dream.png"),
          portfolioName: "Dream Pedlary",
          portfolioType: "YouTube",
        },
        {
          src: "https://youtu.be/Y7awK06et7U",
          thumb: require("../../assets/img/portfolio/boze.png"),
          portfolioName: "Ach bože, božínku",
          portfolioType: "YouTube",
        },
        {
          src: "https://youtu.be/B-YDR-kk-OM",
          thumb: require("../../assets/img/portfolio/duet thumb.png"),
          portfolioName: "Cinque... dieci... venti",
          portfolioType: "YouTube",
        },
        {
          src: "https://www.youtube.com/watch?v=mfXDoTXZIV4",
          thumb: require("../../assets/img/portfolio/spiel.jpeg"),
          portfolioName: "Spiel ich die Unschuld vom Lande",
          portfolioType: "YouTube"
        },
        {
          src: "https://www.youtube.com/watch?v=PDyP3llUZbg",
          thumb: require("../../assets/img/portfolio/je dis.jpeg"),
          portfolioName: "Je dis que rien ne m'epouvante",
          portfolioType: "YouTube"
        },
        {
          src: "https://www.youtube.com/watch?v=O6F9v7d9nfM",
          thumb: require("../../assets/img/portfolio/fledermaus.jpg"),
          portfolioName: "Mein Herr Marquis",
          portfolioType: "YouTube"
        },
        {
          src: "https://www.youtube.com/watch?v=Shl98CMt8g4&",
          thumb: require("../../assets/img/portfolio/creation.jpeg"),
          portfolioName: "The Marv'lous Work",
          portfolioType: "YouTube"
        }
        // https://youtu.be/TPTFL5ohZwk
      ],
      audioClips: [],
      /* audioClips: [
        {
          src: "https://youtu.be/YlBTaj0cXQY",
          thumb: require("../../assets/img/portfolio/15.jpg"),
          portfolioName: "My Song",
          portfolioType: "Youtube",
        },
        {
          src: "https://youtu.be/ZOoVOfieAF8",
          thumb: require("../../assets/img/portfolio/24.jpg"),
          portfolioName: "My Song",
          portfolioType: "Youtube",
        },
      ], */
      galleryItems: [
        {
          src: require("../../assets/img/portfolio/bride.jpeg"),
          thumb: require("../../assets/img/portfolio/bride.jpeg"),
          portfolioName: "Le nozze di Figaro",
          portfolioType: "Gallery",
        },
        {
          src: require("../../assets/img/portfolio/dehvieni2.jpg"),
          thumb: require("../../assets/img/portfolio/dehvieni2.jpg"),
          portfolioName: "Le nozze di Figaro",
          portfolioType: "Gallery",
        },
        {
          src: require("../../assets/img/portfolio/figaro.jpg"),
          thumb: require("../../assets/img/portfolio/figaro.jpg"),
          portfolioName: "Le nozze di Figaro",
          portfolioType: "Gallery",
        },
        {
          src: require("../../assets/img/portfolio/figaro2.jpg"),
          thumb: require("../../assets/img/portfolio/figaro2.jpg"),
          portfolioName: "Le nozze di Figaro",
          portfolioType: "Gallery",
        },
        {
          src: require("../../assets/img/portfolio/shock.jpg"),
          thumb: require("../../assets/img/portfolio/shock.jpg"),
          portfolioName: "Le nozze di Figaro",
          portfolioType: "Gallery",
        },
        {
          src: require("../../assets/img/portfolio/slap1.jpg"),
          thumb: require("../../assets/img/portfolio/slap1.jpg"),
          portfolioName: "Le nozze di Figaro",
          portfolioType: "Gallery",
        },
        {
          src: require("../../assets/img/portfolio/slap2.jpg"),
          thumb: require("../../assets/img/portfolio/slap2.jpg"),
          portfolioName: "Le nozze di Figaro",
          portfolioType: "Gallery",
        },
        {
          src: require("../../assets/img/portfolio/sorry.jpeg"),
          thumb: require("../../assets/img/portfolio/sorry.jpeg"),
          portfolioName: "Le nozze di Figaro",
          portfolioType: "Gallery",
        },
        {
          src: require("../../assets/img/portfolio/iolanthe.jpeg"),
          thumb: require("../../assets/img/portfolio/iolanthe.jpeg"),
          portfolioName: "Iolanthe",
          portfolioType: "Gallery",
        },
                {
          src: require("../../assets/img/portfolio/grand duke2.jpeg"),
          thumb: require("../../assets/img/portfolio/grand duke2.jpeg"),
          portfolioName: "The Grand Duke",
          portfolioType: "Gallery",
        },
        {
          src: require("../../assets/img/portfolio/grand duke.jpeg"),
          thumb: require("../../assets/img/portfolio/grand duke.jpeg"),
          portfolioName: "The Grand Duke",
          portfolioType: "Gallery",
        },
        {
          src: require("../../assets/img/portfolio/Cosi1.jpg"),
          thumb: require("../../assets/img/portfolio/Cosi1.jpg"),
          portfolioName: "Cosi fan tutte",
          portfolioType: "Gallery",
        },
                {
          src: require("../../assets/img/portfolio/Cosi2.jpg"),
          thumb: require("../../assets/img/portfolio/Cosi2.jpg"),
          portfolioName: "Cosi fan tutte",
          portfolioType: "Gallery",
        },
                {
          src: require("../../assets/img/portfolio/Cosi4.jpg"),
          thumb: require("../../assets/img/portfolio/Cosi4.jpg"),
          portfolioName: "Cosi fan tutte",
          portfolioType: "Gallery",
        },
                {
          src: require("../../assets/img/portfolio/Cosi5.jpg"),
          thumb: require("../../assets/img/portfolio/Cosi5.jpg"),
          portfolioName: "Cosi fan tutte",
          portfolioType: "Gallery",
        },
                {
          src: require("../../assets/img/portfolio/Cosi6.jpg"),
          thumb: require("../../assets/img/portfolio/Cosi6.jpg"),
          portfolioName: "Cosi fan tutte",
          portfolioType: "Gallery",
        },
                {
          src: require("../../assets/img/portfolio/Cosi7.jpg"),
          thumb: require("../../assets/img/portfolio/Cosi7.jpg"),
          portfolioName: "Cosi fan tutte",
          portfolioType: "Gallery",
        },
                {
          src: require("../../assets/img/portfolio/Cosi8.jpg"),
          thumb: require("../../assets/img/portfolio/Cosi8.jpg"),
          portfolioName: "Cosi fan tutte",
          portfolioType: "Gallery",
        },
        {
          src: require("../../assets/img/portfolio/malabar1.jpeg"),
          thumb: require("../../assets/img/portfolio/malabar1.jpeg"),
          portfolioName: "Malabar Photoshoot",
          portfolioType: "Gallery",
        },
                {
          src: require("../../assets/img/portfolio/malabar2.jpeg"),
          thumb: require("../../assets/img/portfolio/malabar2.jpeg"),
          portfolioName: "Malabar Photoshoot",
          portfolioType: "Gallery",
        },
                {
          src: require("../../assets/img/portfolio/malabar3.jpeg"),
          thumb: require("../../assets/img/portfolio/malabar3.jpeg"),
          portfolioName: "Malabar Photoshoot",
          portfolioType: "Gallery",
        },
                {
          src: require("../../assets/img/portfolio/malabar4.jpeg"),
          thumb: require("../../assets/img/portfolio/malabar4.jpeg"),
          portfolioName: "Malabar Photoshoot",
          portfolioType: "Gallery",
        },
                        {
          src: require("../../assets/img/portfolio/candide1.jpeg"),
          thumb: require("../../assets/img/portfolio/candide1.jpeg"),
          portfolioName: "Candide",
          portfolioType: "Gallery",
        },
                        {
          src: require("../../assets/img/portfolio/mid1.jpeg"),
          thumb: require("../../assets/img/portfolio/mid1.jpeg"),
          portfolioName: "A Northern Lights Dream",
          portfolioType: "Gallery",
        },
                        {
          src: require("../../assets/img/portfolio/night1.jpeg"),
          thumb: require("../../assets/img/portfolio/night1.jpeg"),
          portfolioName: "Night Blooming Cereus Flower",
          portfolioType: "Gallery",
                        },
        {
          src: require("../../assets/img/portfolio/zerlina1.jpeg"),
          thumb: require("../../assets/img/portfolio/zerlina1.jpeg"),
          portfolioName: "Don Giovanni",
          portfolioType: "Gallery",
        },
                        {
          src: require("../../assets/img/portfolio/zerlina2.jpeg"),
          thumb: require("../../assets/img/portfolio/zerlina2.jpeg"),
          portfolioName: "Don Giovanni",
          portfolioType: "Gallery",
        },
      ],
      index: null,
    };
  },
  computed: {
    allItems () {


      const all = this.youtubeItems.concat(this.galleryItems).concat(this.audioClips);
      return  all.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value);
    }
  },
};
</script>

<style lang="scss" scoped></style>
