<template>
  <div class="container">
    <div
      class="positon-relative"
      v-scroll-reveal="{ delay: 50, distance: '20px' }"
    >
      <div class="shane_tm_title">
        <div class="title_flex">
          <div class="left">
            <span>Upcoming Events</span>
          </div>
        </div>
      </div>

      <div class="tabcontent">
        <div v-for="(event, index) in events.upcoming" :key="index" class="event">
          <a :href="event.link" target="_blank">
            <div v-html="event.date"></div>
            <div>{{ event.company }}</div>
            <div><b>{{ event.title }}</b></div>
            <div><i>{{ event.role }}</i></div>
          </a>
        </div>
      </div>

      <div class="shane_tm_title">
        <div class="title_flex">
          <div class="left">
            <span>Recent Past Events</span>
          </div>
        </div>
      </div>

      <div class="tabcontent">
        <div v-for="(event, index) in events.past" :key="index" class="event">
          <a :href="event.link" target="_blank">
            <div v-html="event.date"></div>
            <div>{{ event.company }}</div>
            <div><b>{{ event.title }}</b></div>
            <div><i>{{ event.role }}</i></div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      activetab: 1,
      events: {
        upcoming: [
          { date: '2024-08-06 to <br /> 2024-08-07', company: 'Toronto Concert Orchestra', title: 'Le nozze di Figaro', link: 'https://www.tcomusic.ca/', role: "Susanna"},
          { date: '2024-08-09 to <br /> 2024-08-18', company: 'No Strings Theatre', title: 'The Magic Flute', role: "Pamina", link: ''},
          { date: '2024-09-13', company: 'Canadian Institue of Czech Music', title: 'Jakobin', link: 'https://myevent.com/jakobin', role: "Terinka"},
          { date: '2024-11-01 to <br /> 2024-11-03', company: 'Opera York', title: "L'elisir d'amore", link: 'https://operayork.com/whats-on/', role: "Giannetta"},
          { date: '2024-11-14', company: 'Canadian Opera Company', title: 'Free Concert Series: The Voices of Prague', link: 'https://www.coc.ca/tickets/2425-season/the-voices-of-prague', role: "Soloist"},
          { date: '2024-12-14', company: 'Chorus York', title: 'Vivaldi: Gloria', link: 'https://www.chorusyork.ca/', role: "Soprano Soloist"},
        ],
        past: [
          { date: '2022-12-03', company: 'Georgtown Choral Society', title: 'A Big Brass Christmas', link: 'https://www.georgetownchoral.ca/event/big-brass-christmas/', role: "Soprano Soloist"},
          { date: '2022-12-11', company: 'Blessed Trinity', title: 'Hallelujah - Blessed Trinity Christmas Concert', link: 'https://fb.me/e/32LFrwizr/', role: "Soprano Soloist"},
          { date: '2023-03-03 <br /> 2023-03-05', company: 'Opera York', title: 'The Magic Flute', link: 'https://tickets.rhcentre.ca/Online/default.asp?doWork::WScontent::loadArticle=Load&BOparam::WScontent::loadArticle::article_id=BDA325C7-0443-4CCB-BC09-1DB4B7872F91', role: "Papagena"},
          { date: '2023-03-11', company: 'Ottawa Bach Choir', title: 'Brahms’ Ein deutsches Requiem', link: 'https://ottawabachchoir.ca/en/product/brahms-ein-deutsches-requiem-2/'},
          { date: '2023-03-17', company: 'Opera by Request', title: 'Cosi fan tutte', link: 'https://gracequinsey.ca/cosi', role: "Fiordiligi"},
          { date: '2023-05-07', company: 'Ottawa Bach Choir', title: 'Spring Serenade', link: 'https://ottawabachchoir.ca/en/product/spring-serenade/'},
          { date: '2023-08-13 to <br /> 2023-08-20', company: 'No Strings Theatre', title: 'Carmen', role: "Micaëla", link: 'https://ottawabachchoir.ca/en/product/brahms-ein-deutsches-requiem-2/'},
          { date: '2023-10-15', company: 'Toronto Oratorio Society', title: 'Haydn - The Creation', link: '', role: "Gabriel / Eve"},
          { date: '2023-11-03 to <br /> 2023-11-05', company: 'Opera York', title: "Die Fledermaus", link: 'https://operayork.com/whats-on/die-fledermaus/', role: "Adele"},
          { date: '2023-11-18', company: 'Nocturnes', title: "The Voices of Prague", link: '', role: "Soprano Soloist"},
          { date: '2023-11-26', company: 'OperOttawa', title: 'The Magic Flute', role: "First Lady"},
          { date: '2023-12-02', company: 'Ottawa Bach Choir', title: 'Magnificat', link: 'https://ottawabachchoir.ca/en/product/magnificat/'},
          { date: '2023-02-14', company: 'Opera York', title: "Valentine's Concert at the Arts & Letters Club", role: "Soprano Soloist"},
          { date: '2024-03-02', company: 'Ottawa Bach Choir', title: 'The Maestro of San Marco', link: 'https://ottawabachchoir.ca/en/product/magnificat/'},
          { date: '2024-03-08', company: 'St. Andrew -- Friday Noon Recitals', title: 'The Voice of Prague', link: 'https://standrewstoronto.org/events/music-videos/', role: 'Soprano Soloist'},
          { date: '2024-03-09', company: 'Canadian Institue of Czech Music', title: 'Prodaná nevěsta (The Bartered Bride)', link: 'https://myevent.com/bartered-bride', role: "Mařenka"},
        ]
      },
      index: null,
    };
  },
  computed: {
    allItems () {
      return this.youtubeItems.concat(this.galleryItems).concat(this.audioClips);
    }
  },
};
</script>

<style lang="scss" scoped>
.tabcontent {
  // column-count: 3;
  display: inline-flex;
  flex-wrap: wrap;
}
.event {
  padding-top: 20px;
  padding-right: 40px;
  width: 200px;
 //  break-inside: avoid-column;
  a {
    text-decoration: none;
    color: black;
    &:hover {
      cursor: pointer;
      // font-style: bold;
      color: rgb(0, 0, 92);
      // background: black;
    }
  }
}
</style>
