<template>
  <div class="about_inner">
    <div class="left">
      <div class="image">
        <img src="../../assets/img/placeholders/1-1.jpg" alt="placeholder" />
        <div
          class="main"
          v-scroll-reveal="{ delay: 20 }"
          :style="{ backgroundImage: 'url(' + aboutImg + ')' }"
        ></div>
      </div>
      <!-- End image  -->
    </div>
    <!-- End left -->

    <div class="right">
      <div class="shane_tm_title">
        <span v-scroll-reveal="{ delay: 20 }">About Me</span>
        <h3 v-scroll-reveal="{ delay: 40 }">
          Grace Quinsey - Soprano
        </h3>
      </div>
      <div class="text" v-scroll-reveal="{ delay: 60 }">
        <p>
          Windsor born Grace most recently "graced" the stage as Susanna in The Marriage of Figaro with No Strings Theatre.
          Her recent engagements include Fiordiligi (Così fan tutte) with Summer Opera Lyric Theatre, 
          First Lady & Papagena (Die Zauberflöte), Norina (Don Pasquale) with Opera By Request, and Zulma 
          (L’italiana in Algeri) with Vera Causa Opera. She played the roles of Helen & Robin in a 
          workshopped version of Michael Rose’s A Northern Lights Dream. Grace also performs with 
          Toronto Operetta Theatre and Opera In Concert. Other roles include Zerlina (Don Giovanni), 
          Alice (Night Blooming Cereus Flower), and the soprano lead in several of Gilbert & Sullivan’s 
          operettas. Grace has a comprehensive Cecchetti ballet background, and she has been noted for her 
          unique characterization, insightful musicality, and dramatic stage presence. When not on stage, 
          Grace works as a software developer and is a video game aficionado. 
        </p>
      </div>
      <div class="shane_tm_button" v-scroll-reveal="{ delay: 80 }">
        <a :href="cv" download>
          Download CV
        </a>
      </div>
    </div>
    <!-- End right -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      aboutImg: require("../../assets/img/about/figaro1.jpg"),
      cv: require("../../assets/img/resume/Grace-Quinsey-CV.png"),
    };
  },
};
</script>
