<template>
  <div class="tokyo_tm_contact">
    <div class="" v-if="showThanks" style="position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      padding: 50px;
      background: white;
      z-index: 2;">
      <div class="title">
        <h3>Thank you! You'll hear back shortly.</h3>
      </div>
    </div>
    <div class="fields">
      <ValidationObserver>
        <form class="contact_form" @submit.prevent="sendEmailTest" name="contact-form" method="POST" enctype="text/plain" netlify data-netlify="true" data-netlify-honeypot="bot-field">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact-form1" />
          <div class="first">
            <ul>
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ errors }"
              >
                <li>
                  <input
                    name="user_names"
                    v-model="formData.name"
                    type="text"
                    placeholder="Name"
                    autocomplete="off"
                  />
                  <span class="input-error">{{ errors[0] }}</span>
                </li>
              </ValidationProvider>

              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <li>
                  <input
                    name="user_email"
                    type="text"
                    rules="required|email"
                    v-model="formData.email"
                    placeholder="email"
                  />
                  <span class="input-error">{{ errors[0] }}</span>
                </li>
              </ValidationProvider>
              <ValidationProvider
                name="message"
                rules="required"
                v-slot="{ errors }"
              >
                <li>
                  <textarea
                    v-model="formData.message"
                    name="message" 
                    placeholder="Message"
                  ></textarea>
                  <span class="input-error">{{ errors[0] }}</span>
                </li>
              </ValidationProvider>
            </ul>
          </div>
          <div class="tokyo_tm_button">
            <button type="submit" class="white-fill-bg fill-black">
              Send Message
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <!-- END FIELDS -->
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import emailjs from 'emailjs-com';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      formData: {
        name: "",
        email: "",
        message: "",
      },
      showThanks: false,
    };
  },
  methods: {
    sendEmailTest: function (e) {
      this.showThanks = true;
      this.sendEmail(e);
    },
    sendEmail: (e) => {
      emailjs.sendForm('rsvp', 'funds_given', e.target, 'user_lPujRw1xek8RC0IEaNR7u')
        .then((result) => {
            console.log('SUCCESS!', result.status, result.text);
        }, (error) => {
            console.log('FAILED...', error);
        });
    }
  },
};
</script>
