var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shane_tm_section"},[_c('div',{staticClass:"shane_tm_skills"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"skills_inner"},[_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({ delay: 50, distance: '50px', duration: 1000 }),expression:"{ delay: 50, distance: '50px', duration: 1000 }"}],staticClass:"left"},[_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"tokyo_progress"},[_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
                delay: 50,
                distance: '20px',
                duration: 1000,
              }),expression:"{\n                delay: 50,\n                distance: '20px',\n                duration: 1000,\n              }"}],staticClass:"progress_inner"},[_vm._m(2),_vm._m(3)]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
                delay: 100,
                distance: '20px',
                duration: 1200,
              }),expression:"{\n                delay: 100,\n                distance: '20px',\n                duration: 1200,\n              }"}],staticClass:"progress_inner"},[_vm._m(4),_vm._m(5)]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
                delay: 150,
                distance: '20px',
                duration: 1300,
              }),expression:"{\n                delay: 150,\n                distance: '20px',\n                duration: 1300,\n              }"}],staticClass:"progress_inner"},[_vm._m(6),_vm._m(7)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shane_tm_title"},[_c('h3',[_vm._v("I have high skills in developing and programming")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" I am working on a professional, visually sophisticated and technologically proficient, responsive and multi-functional personal portfolio template Shane. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('span',{staticClass:"label"},[_vm._v("Web Development")]),_c('span',{staticClass:"number"},[_vm._v("95%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[_c('div',{staticClass:"bar"},[_c('div',{staticClass:"bar_in",staticStyle:{"width":"95%"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('span',{staticClass:"label"},[_vm._v("Brand Identity")]),_c('span',{staticClass:"number"},[_vm._v("80%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[_c('div',{staticClass:"bar"},[_c('div',{staticClass:"bar_in",staticStyle:{"width":"80%"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('span',{staticClass:"label"},[_vm._v("Logo Design")]),_c('span',{staticClass:"number"},[_vm._v("90%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[_c('div',{staticClass:"bar"},[_c('div',{staticClass:"bar_in",staticStyle:{"width":"90%"}})])])
}]

export { render, staticRenderFns }