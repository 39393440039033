<template>
  <div class="container">
    <div
      class="positon-relative"
      v-scroll-reveal="{ delay: 50, distance: '20px' }"
    >
      <div class="shane_tm_title">
        <div class="title_flex">
          <div class="left">
            <span>Repertoire</span>
          </div>
        </div>
      </div>
      <!-- End shane_tm_title -->
      <div class="portfolio_filter">
        <ul>
          <li>
            <a
              v-on:click="activetab = 1"
              :class="[activetab === 1 ? 'active' : '']"
              >All</a
            >
          </li>
          <!-- End All for tabcontent 1 -->
          <li>
            <a
              v-on:click="activetab = 2"
              :class="[activetab === 2 ? 'active' : '']"
              >Opera</a
            >
          </li>
          <li>
            <a
              v-on:click="activetab = 3"
              :class="[activetab === 3 ? 'active' : '']"
              >Operetta</a
            >
          </li>
          <!-- End .Youtube tabcontent 3 -->
          <li>
            <a
              v-on:click="activetab = 4"
              :class="[activetab === 4 ? 'active' : '']"
              >Oratorio</a
            >
          </li>
          <!-- End Mix tabcontent 4 -->
        </ul>
      </div>
      <!-- End .portfolio-filter -->

      
      <div class="tabcontent">
        <div class="portfolio_list" v-if="activetab !== 3 && activetab !== 4">
          <h3>Opera</h3>
          <div class="opera-roles-wrapper">
          <div v-for="(opera, index) in roles.opera" :key="index" class="opera-roles"> 
            <h4>{{ opera.composer }}</h4>
            <div v-if="opera.opera"><b>{{ opera.opera }}</b> - {{ opera.role }}</div>
            <div v-if="opera.operas">
              <div  v-for="(o, key) in opera.operas" :key="key"><b>{{ o.opera }}</b> - {{ o.role}}</div>
            </div>
          </div>
          </div>
        </div>
        <div class="portfolio_list" v-if="activetab !== 2 && activetab !== 4">
          <h3>Operetta</h3>
                    <div class="opera-roles-wrapper">

          <div v-for="(operetta, index) in roles.operetta" :key="index"  class="opera-roles"> 
            <h4>{{ operetta.composer }}</h4>
            <div v-if="operetta.opera"><b>{{ operetta.opera }}</b> - {{ operetta.role }}</div>
            <div v-if="operetta.operas">
              <div  v-for="(o, key) in operetta.operas" :key="key"><b>{{ o.opera }}</b> - {{ o.role}}</div>
            </div>
          </div>
          </div>
        </div>
        <div class="portfolio_list" v-if="activetab !== 2 && activetab !== 3">
          <h3>Oratorio</h3>
                    <div class="opera-roles-wrapper">

          <div v-for="(ora, index) in roles.oratorio" :key="index"  class="opera-roles"> 
            <h4>{{ ora.composer }}</h4>
            <div v-if="ora.opera"><b>{{ ora.opera }}</b> - {{ ora.role }}</div>
            <div v-if="ora.operas">
              <div  v-for="(o, key) in ora.operas" :key="key"><b>{{ o.opera }}</b> - {{ o.role}}</div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <!-- End .tabcontent 4 -->
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      activetab: 1,
      roles: {
        opera: [
          { composer: 'Dvořák', opera: 'Jakobín', role: 'Terinka' },
          { composer: 'Strauss', opera: 'Die Fledermaus', role: 'Adele' },
          { composer: 'Smetana', opera: 'Prodaná nevěsta (The Bartered Bride)', role: 'Mařenka' },
          { composer: 'Bizet', opera: 'Carmen', role: 'Micaēla' },
          { composer: 'Mozart', operas: [{ opera: 'Le nozze di Figaro', role: 'Susanna' }, { opera: 'Cosi fan tutte', role: 'Fiordiligi' }, { opera: 'Don Giovanni', role: 'Zerlina' }, { opera: 'Die Zauberflӧte', role: 'Pamina' }, { opera: 'Die Zauberflӧte', role: 'First Lady' }, { opera: 'Die Zauberflӧte', role: 'Second Lady' }, { opera: 'Die Zauberflӧte', role: 'First Spirit' }, { opera: 'Die Zauberflӧte', role: 'Papagena' }] },
          { composer: 'Donizetti', operas: [{opera: 'Don Pasquale', role: 'Norina' }, {opera: `L'elisir d'amore`, role: 'Giannetta' }]},
          { composer: 'John Beckwith', opera: 'Night Blooming Cereus Flower', role: 'Alice' },
          { composer: 'Michael Rose', operas: [{opera: 'A Northern Lights Dream', role: 'Helen' },{ opera: 'A Northern Lights Dream', role: 'Robin' }]},
          { composer: 'Thomson & Stein', opera: 'The Mother of Us All', role: 'Lillian Russel' },
        ],
        operetta: [
          { composer: 'Gilbert & Sullivan', operas: [{ opera: 'The Grand Duke', role: 'Julia' }, { opera: 'Iolanthe', role: 'Celia' }, { opera: 'Patience', role: 'Patience' }, { opera: 'HMS Pinafore', role: 'Josephine' }, { opera: 'The Gondoliers', role: 'Casilda' }]},
          { composer: 'Bernstein', opera: 'Candide', role: 'Chorus' },
          { composer: 'Straus', opera: 'The Chocolate Soldier', role: 'Chorus' },
        ],
        oratorio: [
          { composer: 'Vivaldi', opera: 'Gloria in D Major', role: 'Soprano Soloist' },
          { composer: 'Bach', opera: 'Wachet Auf, Ruft U"ns Die Stimme, BWV 140', role: 'Soprano Soloist' },
          { composer: 'Handel', opera: "Messiah (Part 1)", role: 'Soprano Soloist' },
          { composer: 'Buxtehude', opera: "Membra Jesu nostri", role: 'Second Soprano Soloist' },
          { composer: 'Haydn', operas: [{opera: "The Creation", role: 'Gabriel' }, {opera: "The Creation", role: 'Eve'}]},
        ]
      },
      index: null,
    };
  },
  computed: {
    allItems () {
      return this.youtubeItems.concat(this.galleryItems).concat(this.audioClips);
    }
  },
};
</script>

<style lang="scss" scoped>
.opera-roles {
  // width: 200px;
  padding-top: 20px;
  break-inside: avoid-column;
}
.opera-roles-wrapper {
  column-count: 3;
  padding: 20px 0;
}
</style>
